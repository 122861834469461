import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-modesto-california.png'
import image0 from "../../images/cities/scale-model-of-escape-modesto-in-modesto-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Modesto'
            state='California'
            image={image}
            lat='37.63909719999999'
            lon='-120.99687819999997'
            attractions={ [{"name": "ESCAPE Modesto", "vicinity": "912 11th St, Modesto", "types": ["point_of_interest", "establishment"], "lat": 37.6407286, "lng": -120.99847540000002}] }
            attractionImages={ {"ESCAPE Modesto":image0,} }
       />);
  }
}